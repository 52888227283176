import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import GitHubIcon from '@material-ui/icons/GitHub';
import FolderSpecialIcon from '@material-ui/icons/FolderSpecial';
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    backgroundColor: "#1b2b43",
    color: "white"
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
      color: "white"
  },
  description: {
      padding: "8%",
      color: "white"
  }
}));

export default function RecipeReviewCard() {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
            <FolderSpecialIcon />
        }
        action={
          <IconButton className = {classes.avatar}>
            <GitHubIcon />
          </IconButton>
        }
        title="Stock Market Predictor"
      />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
            <Box className = {classes.description}>
                A single page web app for helping me choose where to travel, built with Next.js, Firebase, and Tailwind CSS
            </Box>
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
          <Box>
            React Django AWS
          </Box>
      </CardActions>
    </Card>
  );
}