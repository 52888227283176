import React, { Fragment } from 'react'
import PropTypes from 'prop-types';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import FolderIcon from '@material-ui/icons/Folder';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import SourceCodePro from '../../assets/fonts/Calibre/Calibre-Semibold.ttf'

const raleway = {
  fontFamily: 'Raleway',
  fontStyle: 'regylar',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    url(${SourceCodePro}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Raleway, Arial',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [raleway],
      },
    },
  },
  palette: {
    divider: "#FBA16C"
  }
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
	const classes = useStyles();

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        style = {{width: "55%"}}
        {...other}
      >
        {value === index && (
			<Box p={3}>
				<Box>
					Engineer <span className = {classes.coloredIcon}>@ Intuit</span>
				</Box>
				<Box>
					September 2020 - Present
				</Box>
				<Box>
					<div>
						<List>
								<ListItem>
									<ListItemIcon>
										<ArrowForwardIosIcon className = {classes.coloredIcon}/>
									</ListItemIcon>
									<ListItemText
									primary="Introduced BI tool using NetworkX and MatPlotLib to visualize content network and cyclic dependancies"
									/>
								</ListItem>
								<ListItem>
									<ListItemIcon>
										<ArrowForwardIosIcon className = {classes.coloredIcon}/>
									</ListItemIcon>
									<ListItemText
									primary="Created a content search engine utilizing AWS ElasticSearch helping TurboTax content authors effectively
									search through tax documentation, yielding a 12.3% improvement in click-rate"
									/>
								</ListItem>
								<ListItem>
									<ListItemIcon>
										<ArrowForwardIosIcon className = {classes.coloredIcon} />
									</ListItemIcon>
									<ListItemText
									primary="Integrated Python migration pipeline in existing content environments, increasing data throughput by 57%"
									/>
								</ListItem>
						</List>
					</div>
				</Box>
			</Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      display: 'flex',
	  color: "white",
	  marginLeft: "23%"
    },
    sectionTitle: {
        color: "white",
		paddingTop: "2%",
		marginLeft: "7%"
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
	},
	coloredIcon: {
		color: "#FBA16C"
	},
	line : {
        content: "",
        display: "block",
        height: "1px",
        width: "30%",
		marginTop: "3.5%",
		marginLeft: "2%",
        backgroundColor: "rgb(168, 178, 209)"
	},
	boxLine: {
		display: "flex",
		flexDirection: "row"
	}
  }));


  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

const Work = () => {

    const classes = useStyles();
    const [value, setValue] = React.useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    return (
        <Fragment>
        	<ThemeProvider theme={theme}>
            	<CssBaseline />
				<Box className = {classes.boxLine}>
					<Typography className = {classes.sectionTitle} variant="h3" gutterBottom>
                		Work Experience
            		</Typography>
					<div className = {classes.line} />
				</Box>

            </ThemeProvider>
             <div className={classes.root}>
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    className={classes.tabs}
                >
                    <Tab label="Intuit (SWE)" {...a11yProps(0)} />
                    <Tab label="Intuit (Data)" {...a11yProps(1)} />
                    <Tab label="TimeSaved" {...a11yProps(2)} />
                    <Tab label="EllisDon" {...a11yProps(3)} />
                    <Tab label="Nissan" {...a11yProps(4)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                  <Box>
                    I had the opportunity to work as a software engineer intern at one of the largest financial software companies in the world. I worked on improving the Java-based TurboTax platform as well as using Python to plan and execute migration projects.
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  I had the opportunity to work as a software engineer intern at one of the largest financial software companies in the world. I worked on improving the Java-based TurboTax platform as well as using Python to plan and execute migration projects.
                </TabPanel>
                <TabPanel value={value} index={2}>
                I worked as a full-stack software developer for TimeSaved, a tech startup bringing the concept of Uber to the staffing and recruitment world. I learned a lot in terms of Express and back-end web development in general through this co-op experience.
                </TabPanel>
                <TabPanel value={value} index={3}>
                This was my very first software developer role. I worked as a front-end web developer helping build EllisDon's contruction project management software which is used on their construction sites. I gained valuable skills in front-end web development and software architecture.
                </TabPanel>
                <TabPanel value={value} index={4}>
                My first co-op placement was as a software quality assurance analyst at Nissan Canada. Throughout my term, I worked on regression and smoke testing for their iOS and Android mobile apps, which were used to control and interact with Nissan and Infiniti vehicles.
                </TabPanel>
            </div>
        </Fragment>
    )
}

export default Work