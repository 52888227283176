import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import './App.css';

import Navbar from './components/layout/Navbar'
import Landing from './components/landing/Landing'

const THEME = createMuiTheme({
	typography: {
	 "fontFamily": `"Roboto", "Helvetica", "Arial", sans-serif`,
	 "fontSize": 14,
	 "fontWeightLight": 300,
	 "fontWeightRegular": 400,
	 "fontWeightMedium": 500
	},
	palette: {
		primary: {
			main: "#0b1829"
		}
	}
 });

const App = () => { 
	return (
		<MuiThemeProvider theme={THEME}>
			<CssBaseline />
			<Router>
				<Fragment>
					{/* <Navbar /> */}
					<Switch>
						<Route exact path ='/' component = {Landing} />
					</Switch>
				</Fragment>
			</Router>
		</MuiThemeProvider>
	)
}

export default App;
