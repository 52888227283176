import React from 'react'
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Icon from '@material-ui/core/Icon';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import Logo from "../../assets/images/Tiger.svg"


import SourceCodePro from '../../assets/fonts/Calibre/Calibre-Semibold.ttf'

const raleway = {
  fontFamily: 'Raleway',
  fontStyle: 'regular',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    url(${SourceCodePro}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const defaultTheme = createMuiTheme()
const { breakpoints, typography: { pxToRem } } = defaultTheme

const theme = createMuiTheme({
    typography: {
      fontFamily: 'Raleway, Arial',
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': [raleway],
        },
      },
      MuiTypography: {
        h6: {
          fontSize: "2rem",
            [breakpoints.down("xs")]: {
                fontSize: "1.25rem"
            }
          }
    }
    },
    palette: {
		primary: {
			main: "#0b1829"
		}
	}
  });

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: "none",
        flexGrow: 1,
        padding: "0"
    },
    appBarContent: {
        // padding: "1% 10%"
    },
    title: {
      flexGrow: 1,
    },
    lastName: {
        color : "#FBA16C"
    },
    item:{
      [breakpoints.up("md")]: {
        display: "none"
      }
    },
    imageIcon: {
        height: '70px',
        width: "70px"
      },  
    iconRoot: {
        width: "auto",
        height: "auto",
        textAlign: 'center'
      }
  }));


const Navbar = () => {

    const classes = useStyles();

    return (
        <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container className={classes.root}>
            <AppBar position="static">
                <Toolbar className = {classes.appBarContent}>
                    <Typography variant="h6" className={classes.title}>
                        T<span className={classes.lastName}>N</span>
                    </Typography>
                    <IconButton className = {classes.item} aria-label="add to shopping cart">
                      <Link className = {classes.item} href="https://www.watchtyger.com/" target="_blank"> 
                        <Icon className = {classes.iconRoot}>
                            <img className={classes.imageIcon} src={Logo}/>
                        </Icon>
                      </Link>
                    </IconButton>
                    {/* <Button color="inherit">
                        <Box className={classes.title}>
                          <Typography variant="h6" className={classes.title}>
                              Resumé
                          </Typography>
                        </Box>
                    </Button> */}
                </Toolbar>
            </AppBar>
        </Container>
        </ThemeProvider>
    )
}

export default Navbar