import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import EmailIcon from '@material-ui/icons/Email';
import BookIcon from '@material-ui/icons/Book';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: "none",
        flexGrow: 1,
        padding: "0"
    },
    appBarContent: {
        padding: "0 10%"
    },
    title: {
      flexGrow: 1,
    },
    item: {
        color: "white",
        display: "flex",
        justifyContent: "space-around"
    },
    list : {
        position: "fixed",
        bottom: "0",
        zindex: "10"
    }, 
    line : {
        content: "",
        display: "block",
        width: "1px",
        height: "90px",
        margin: "0px auto",
        backgroundColor: "rgb(168, 178, 209)"
    }
  }));

const SocialLinks = () => {

    const classes = useStyles();
    const preventDefault = (event) => event.preventDefault();

    return (
        <Fragment>
            <List className = {classes.list} component="nav">
                <ListItem className = {classes.item} >
                    <IconButton >
                        <Link className = {classes.item} href="https://github.com/tharseken" target="_blank"> 
                            <GitHubIcon style={{ fontSize: 30 }} />
                        </Link>
                    </IconButton>
                </ListItem>
                <ListItem className = {classes.item} >
                    <IconButton >
                        <Link className = {classes.item} href="https://www.linkedin.com/in/tharseken/" target="_blank"> 
                            <LinkedInIcon style={{ fontSize: 30 }} />
                        </Link>
                    </IconButton>
                </ListItem>
                <ListItem className = {classes.item} >
                    <IconButton >
                        <Link className = {classes.item} href="mailto:tnavajee@uwaterloo.ca" target="_blank"> 
                            <EmailIcon style={{ fontSize: 30 }} />
                        </Link>
                    </IconButton>
                </ListItem>
                <ListItem className = {classes.item} >
                    <IconButton >
                        <Link className = {classes.item} href="https://medium.com/@tharseken.n" target="_blank"> 
                            <BookIcon style={{ fontSize: 30 }} />
                        </Link>
                    </IconButton>
                </ListItem>
                <ListItem className = {classes.item} >
                    <IconButton >
                        <Link className = {classes.item} href="https://tharseken-resume-bucket.s3.us-east-2.amazonaws.com/resume.pdf" target="_blank"> 
                            <PictureAsPdfIcon style={{ fontSize: 30 }} />
                        </Link>
                    </IconButton>
                </ListItem>
                <ListItem>
                    <div className = {classes.line} />
                </ListItem>
            </List>
        </Fragment>

    )
}

export default SocialLinks