import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
// import ThemeProvider from '@material-ui/core/ThemeProvider';

import SocialLinks from './SocialLinks'
import SocialLinksFooter from './SocialLinksFooter'
import Branding from './Branding'

// Sections
import About from '../sections/About'
import Work from '../sections/Work'
import Projects from '../sections/Projects'
import Blog from '../sections/Blog'

const theme = createMuiTheme({
    palette: {
		background: {
			default: "#0b1829"
        },    
    }
  });

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: "none",
        flexGrow: 1,
        padding: "0",
        [theme.breakpoints.down('sm')]: {
            display: "none"
        },
    },
    rootMobile: {
        maxWidth: "none",
        flexGrow: 1,
        padding: "0",
        [theme.breakpoints.up('md')]: {
            display: "none"
        },
    },
    social: {
        padding: theme.spacing(2),
        height: "100%",
        background: "#0b1829",
        borderRadius: "0px",
        boxShadow: "none"
    },
    content: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        height: "100%",
        background: "#0b1829",
        borderRadius: "0px",
        boxShadow: "none"
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: "100%",
        background: "#0b1829",
        borderRadius: "0px",
        boxShadow: "none"
    },
  }));

const Landing = () => {

    const classes = useStyles();

    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            {/* Desktop / Tablet */}
            <Grid className = {classes.root} container>
                {/* <Grid item xs={1}>
                    <Paper className={classes.social}>
                        <Box>
                            <SocialLinks />
                        </Box>
                    </Paper>
                </Grid> */}
                <Grid item xs={12}> 
                    <Paper className={classes.content}>
                        <About />
                        {/* <Work />
                        <Projects />
                        <Blog /> */}
                        <Box margin="0 auto" width="50vw">
                            <SocialLinksFooter/>
                        </Box>
                    </Paper>
                </Grid>
                {/* <Grid item xs={1}>
                    <Paper className={classes.paper}>
                        <Box>
                            <Branding />
                        </Box>
                    </Paper>
                </Grid> */}
            </Grid>
            {/* Mobile */}
            <Grid className = {classes.rootMobile} container>
                <Grid item xs={12}> 
                    <Paper className={classes.content}>
                        <About />
                        {/* <Work />
                        <Projects />
                        <Blog /> */}
                        <SocialLinksFooter/>
                    </Paper>
                </Grid>
            </Grid>
            
        </MuiThemeProvider>
    )
}

export default Landing