import React, { Fragment } from 'react'
import PropTypes from 'prop-types';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import axios from 'axios'


import SourceCodePro from '../../assets/fonts/Calibre/Calibre-Semibold.ttf'

const raleway = {
  fontFamily: 'Raleway',
  fontStyle: 'regylar',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    url(${SourceCodePro}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Raleway, Arial',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [raleway],
      },
    },
  },
  palette: {
    divider: "#FBA16C"
  }
});

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      display: 'flex',
      color: "white"
    },
    sectionTitle: {
        color: "white",
        paddingTop: "10%"
    },
    boxLine: {
      display: "flex",
      flexDirection: "row"
      },
      sectionTitle: {
          color: "white",
      paddingTop: "2%",
      marginLeft: "7%"
      },
      line : {
          content: "",
          display: "block",
          height: "1px",
          width: "30%",
      marginTop: "3.5%",
      marginLeft: "2%",
          backgroundColor: "rgb(168, 178, 209)"
    },
  }));


const Blog = () => {

    const classes = useStyles();

    return (
        <Fragment className = {classes.root}>
            <ThemeProvider theme={theme}>
            	<CssBaseline />
              <Box className = {classes.boxLine}>
                <Typography className = {classes.sectionTitle} variant="h3" gutterBottom>
                  My Blog
                </Typography>
                <div className = {classes.line} />
              </Box>
            </ThemeProvider>
            <Typography variant="h6" gutterBottom>
                Check out my latest blog post
            </Typography>
        </Fragment>
    )
}

export default Blog