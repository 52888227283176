import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Logo from "../../assets/images/Tiger.svg"
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: "none",
        flexGrow: 1,
        padding: "0"
    },
    appBarContent: {
        padding: "0 10%"
    },
    title: {
      flexGrow: 1,
    },
    item: {
        color: "white",
        display: "flex",
        justifyContent: "space-around"
    },
    list : {
        position: "fixed",
        bottom: "0",
        right: "1%",
        zindex: "10"
    }, 
    line : {
        content: "",
        display: "block",
        width: "1px",
        height: "90px",
        margin: "0px auto",
        backgroundColor: "rgb(168, 178, 209)"
    },
    imageIcon: {
        height: '70px',
        width: "70px"
      },  
    iconRoot: {
        width: "auto",
        height: "auto",
        textAlign: 'center'
      }
  }));

const Branding = () => {

    const classes = useStyles();

    return (
        <Fragment>
            <List className = {classes.list} component="nav">
                <ListItem className = {classes.item} >
                    <IconButton className = {classes.item} aria-label="add to shopping cart">
                        <Link className = {classes.item} href="https://www.watchtyger.com/" target="_blank"> 
                            <Icon className = {classes.iconRoot}>
                                <img className={classes.imageIcon} src={Logo}/>
                            </Icon>
                        </Link>
                    </IconButton>
                </ListItem>
                <ListItem>
                <div className = {classes.line} />
                </ListItem>
            </List>

        </Fragment>

    )
}

export default Branding