import React, { Fragment } from 'react'
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import SourceCodePro from '../../assets/fonts/Calibre/Calibre-Semibold.ttf'

const raleway = {
  fontFamily: 'Raleway',
  fontStyle: 'regylar',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    url(${SourceCodePro}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const useStyles = makeStyles((theme) => ({
    root: {
        color: "white",
    },
    title: {
        padding: "10% 0% 0% 0%",
        [breakpoints.up("sm")]: {
            padding: "10% 10% 0% 10%"
        },
        [breakpoints.down("xs")]: {
            paddingTop: "30%"
        },
    },
    subHeading: {
        marginBottom: "10%",
        padding: "30% 0% 0% 0%",
        [breakpoints.up("sm")]: {
            padding: "10% 10% 0% 10%"
        },
    },
    firstName: {
        marginTop: "-5%",
        color : ""
    },
    lastName: {
        marginTop: "-5%",
        color : "#FBA16C"
    },
    tamil: {
        display: "flex",
        flexDirection: "row"
    },
    tamilLastName: {
        marginLeft: "1%",
        color : "#FBA16C"
    },
    work: {
        color: "#FF9900"
    },
    school: {
        color: "gold"
    }
  }));


const defaultTheme = createMuiTheme()
const { breakpoints, typography: { pxToRem } } = defaultTheme

  const theme = createMuiTheme({
    typography: {
      fontFamily: 'Raleway, Arial',
    },
    palette: {
        background: {
            default: "#0b1829"
        }
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [raleway],
            },
        },
        MuiTypography: {
            h1: {
              [breakpoints.down("xs")]: {
                fontSize: "2.5rem"
              },
              [breakpoints.up("sm")]: {
                fontSize: "5rem"
              },
              [breakpoints.up("md")]: {
                fontSize: "500%"
              }
            },
            h4: {
                [breakpoints.down("xs")]: {
                    paddingTop: "15%",
                    fontSize: "1.5rem"
                },
                [breakpoints.up("sm")]: {
                    fontSize: "2rem"
                  }
            },
            h5: {
                [breakpoints.down("sm")]: {
                    // paddingTop: "10%",
                    fontSize: "1.25rem"
                },
                [breakpoints.up("sm")]: {
                    fontSize: "2rem"
                  }
            }
        }
    },
  });
  

const About = () => {

    const classes = useStyles();

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Container className={classes.root}>
                <Box className = {classes.title}>
                    <Typography className = {classes.firstName} variant="h1" gutterBottom>
                        Tharseken
                    </Typography>
                    <Typography className = {classes.lastName} variant="h1" gutterBottom>
                        Navajeevayokan
                    </Typography>
                    {/* <Box className = {classes.tamil}>
                        <Typography variant="h4" gutterBottom>
                            தர்சிகன் 
                        </Typography>
                        <Typography className = {classes.tamilLastName} variant="h4" gutterBottom>
                             நவஜீவயோகன்
                        </Typography>
                    </Box> */}
                </Box>
                <Box className = {classes.subHeading}>
                    <Typography variant="h5" gutterBottom>
                        Computer Engineering '22 @ <Link href="https://uwaterloo.ca/" target="_blank"><span className = {classes.school}>UWaterloo</span></Link>
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                        Software Development Engineer Intern @ <Link href="https://www.aboutamazon.com/" target="_blank"><span className = {classes.work}>Amazon </span></Link>
                    </Typography>
                </Box>
            </Container>
        </ThemeProvider>
    )
}

export default About