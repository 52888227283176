import React, { Fragment } from 'react'
import PropTypes from 'prop-types';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import GitHubIcon from '@material-ui/icons/GitHub';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import Placeholder from "../../assets/placeholder.jpg"

import ProjectCard from "./ProjectCard"

import SourceCodePro from '../../assets/fonts/Calibre/Calibre-Semibold.ttf'

const raleway = {
  fontFamily: 'Raleway',
  fontStyle: 'regylar',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    url(${SourceCodePro}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Raleway, Arial',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [raleway],
      },
    },
  },
  palette: {
    divider: "#FBA16C"
  }
});

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      display: 'flex',
      color: "white"
    },
    sectionTitle: {
        color: "white",
        paddingTop: "10%"
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    social: {
        backgroundColor: "#0b1829",
        boxShadow: "none"
    },
    grid : {
        flexGrow: 1
    },
    image: {
        width: "100%",
        height: "auto"
    },
    projectRight: {
        color: "white",
        textAlign: "right"
    },
    projectLeft: {
        color: "white",
        textAlign: "left"
    },
    projectTitleRight: {
        marginRight: "39%",
    },
    projectTitleLeft: {
        marginLeft: "39%",
    },
    projectDescriptionRight: {
        position: "absolute",
        left: "0",
        margin: "12% 24% 0% 39%",
        backgroundColor: "#14253b",
        boxShadow: "0 8px 6px -6px black",
    },
    projectDescriptionLeft: {
        position: "absolute",
        right: "0",
        margin: "12% 39% 0% 24%",
        backgroundColor: "#14253b",
        boxShadow: "0 8px 6px -6px black",
    },
    descriptionText: {
        padding: "4%"
    },
    card: {
        justifyContent: "center",
        display: "flex",
        padding: "5% 0% 5% 0%",
    },
    line : {
        content: "",
        display: "block",
        height: "1px",
        width: "30%",
		marginTop: "3.5%",
		marginLeft: "2%",
        backgroundColor: "rgb(168, 178, 209)"
	},
	boxLine: {
		display: "flex",
		flexDirection: "row"
    },
    sectionTitle: {
        color: "white",
		paddingTop: "2%",
		marginLeft: "7%"
    },
  }));


const Work = () => {

    const classes = useStyles();

    return (
        <Fragment>
            <ThemeProvider theme={theme}>
            	<CssBaseline />
				<Box className = {classes.boxLine}>
					<Typography className = {classes.sectionTitle} variant="h3" gutterBottom>
                		Projects
            		</Typography>
					<div className = {classes.line} />
				</Box>
            </ThemeProvider>
            <Grid container spacing={3}>
                <Grid item xs={6} >
                    <Container >
                        <img className ={classes.image} src = {Placeholder} />
                    </Container>
                </Grid>
                <Grid item xs={6} className = {classes.projectRight}>
                    <Grid container spacing={1}>
                        <Grid className = {classes.projectTitleRight} item xs={12} >
                            <Typography className = {classes.sectionTitle} variant="h5" gutterBottom>
                                WatchTyger
                            </Typography>   
                        </Grid>

                        <Grid className = {classes.projectTitleRight} item xs={12} >
                            Django React AWS RDS AWS EC2
                        </Grid>
                        <Grid className = {classes.projectTitleRight} item xs={12} >
                            <GitHubIcon />
                            <OpenInNewIcon />
                        </Grid>
                        <Grid className = {classes.projectDescriptionRight} item xs={12} >
                            <Box className = {classes.descriptionText}>
                                A nicer look at your GitHub profile and repo stats. Includes data visualizations of your top languages, starred repositories, and sort through your top repos by number of stars, forks, and size.
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={6} className = {classes.projectLeft}>
                    <Grid container spacing={1}>
                        <Grid className = {classes.projectTitleLeft} item xs={12} >
                            <Typography className = {classes.sectionTitle} variant="h5" gutterBottom>
                                WatchTyger
                            </Typography>   
                        </Grid>

                        <Grid className = {classes.projectTitleLeft} item xs={12} >
                            Django React AWS RDS AWS EC2
                        </Grid>
                        <Grid className = {classes.projectTitleLeft} item xs={12} >
                            <GitHubIcon />
                            <OpenInNewIcon />
                        </Grid>
                        <Grid className = {classes.projectDescriptionLeft} item xs={12} >
                            <Box className = {classes.descriptionText}>
                                A nicer look at your GitHub profile and repo stats. Includes data visualizations of your top languages, starred repositories, and sort through your top repos by number of stars, forks, and size.
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} >
                    <Container >
                        <img className ={classes.image} src = {Placeholder} />
                    </Container>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={6} >
                    <Container >
                        <img className ={classes.image} src = {Placeholder} />
                    </Container>
                </Grid>
                <Grid item xs={6} className = {classes.projectRight}>
                    <Grid container spacing={1}>
                        <Grid className = {classes.projectTitleRight} item xs={12} >
                            <Typography className = {classes.sectionTitle} variant="h5" gutterBottom>
                                WatchTyger
                            </Typography>   
                        </Grid>

                        <Grid className = {classes.projectTitleRight} item xs={12} >
                            Django React AWS RDS AWS EC2
                        </Grid>
                        <Grid className = {classes.projectTitleRight} item xs={12} >
                            <GitHubIcon />
                            <OpenInNewIcon />
                        </Grid>
                        <Grid className = {classes.projectDescriptionRight} item xs={12} >
                            <Box className = {classes.descriptionText}>
                                A nicer look at your GitHub profile and repo stats. Includes data visualizations of your top languages, starred repositories, and sort through your top repos by number of stars, forks, and size.
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Typography className = {classes.sectionTitle} variant="h4" gutterBottom>
                Some Of My Other Projects
            </Typography>


            <div className = {classes.grid}>
                <Grid spacing = {1} container >
                    <Grid className = {classes.gridItem} item xs={4}>
                        <Paper className={classes.social}>
                            <Box className = {classes.card}>
                                <ProjectCard />
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={4}> 
                        <Paper className={classes.social}>
                        <Box className = {classes.card}>
                            <ProjectCard />
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Paper className={classes.social}>
                        <Box className = {classes.card}>
                            <ProjectCard />
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Paper className={classes.social}>
                        <Box className = {classes.card}>
                            <ProjectCard />
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Paper className={classes.social}>
                        <Box className = {classes.card}>
                            <ProjectCard />
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </Fragment>
    )
}

export default Work